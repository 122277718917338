import { Routes } from '@angular/router';
import { CleanComponent } from './layout/clean/clean.component';
import { NotFoundPage } from './pages/notfound/notfound.page';
import { BypassLoginPage } from './pages/auth/bypass-login/bypass-login.page';
import { MainLayoutComponent } from './layout/main/main.component';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'account',
        pathMatch: 'full',
    },
    {
        path: '',
        // component: MainComponent,
        component: MainLayoutComponent,
        loadChildren: () => import('./routes/app.routes'),
    },
    //* Auth routes 
    {
        path: 'bypass-login',
        component: BypassLoginPage
    },
    {
        path: 'auth',
        loadChildren: () => import('./routes/auth.routes'),
    },
    // * Error
    {
        path: '',
        component: CleanComponent,
        children: [
            {
                path: '**',
                redirectTo: 'error/404'
            },
            {
                path: 'error/:error',
                component: NotFoundPage
            }
        ]
    }
];
