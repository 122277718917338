import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { UserModel } from '../../services/user-model';
import { getEnvironment } from '../../environment/environment';

declare var $: any;

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.page.html',
  standalone: true,
  imports: [CommonModule, RouterModule]
})
export class NotFoundPage implements OnInit {

  env = getEnvironment();

  error_code = '404';
  error_detail = 'Página no encontrada';
  error_description = 'Al parecer, la página que estaba buscando no existe.';

  constructor(
    private router: Router,
    private metaTitle: Title,
    public userModel: UserModel,
    private params: ActivatedRoute
  ) {
    console.log(this.params.snapshot.params['error']);
    this.error_code = this.params.snapshot.params['error'];
  }

  ngAfterViewInit(){
    this.metaTitle.setTitle(`${this.userModel.getTitle()} | ${this.error_detail}`);
  }

  ngOnInit() {
  }

  goToLogin() {
    this.router.navigateByUrl('/auth/login');
  }
}
