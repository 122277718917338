import { Component, inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { RouterOutlet } from '@angular/router';
import { environment } from '../environments/environment';
import { Messaging } from '@angular/fire/messaging';
import { FcmService } from '../../../../src/app/services/firebase.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = environment.title;

  sound?: string;

  private messaging = inject(Messaging);

  constructor(
    private metaTitle: Title,
    private meta: Meta,
    private fcmService: FcmService,
  ) {
    console.log('Ready Project');
    this.initializeApp();
    // firebaseMWService.requestPermission().then(token => {
    //   console.log('Token:',token);
    // });
    // firebaseMWService.message$.subscribe((msg) => {
    //   console.log('Message:', msg);
    // });
  }

  async initializeApp() {
    this.metaTitle.setTitle(`${environment.title} | ${environment.subtitle}`);
    this.meta.addTags([
      { name: 'description', content: `${environment.description}.` },
      { name: 'keywords', content: environment.keywords },
    ]);
    
    this.fcmService.playSound$.subscribe(data => {
      this.sound = data;
    })
  }
}
