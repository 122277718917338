 
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { XtrasService } from './xtras.service';
import { HttpClientProvider } from './http-client';
import { SettingsProvider } from './settings';
 
@Injectable({
  providedIn: 'root',
})
export class TicketsService {
  constructor(
    private toastr: ToastrService,
    private xServices: XtrasService,
    private http: HttpClientProvider,
  ) {

  }
 
  success(title: string, message: string, time?: any, closeButton?: boolean, progress?: boolean ) {
    time = time ? time : 6000;
    closeButton = closeButton == false ? closeButton : true;
    progress = progress == false ? progress : true;
    this.toastr.success(message, title, {
      timeOut: time,
      closeButton: closeButton,
      extendedTimeOut: 2000,
      // Icon IN toastClass
      toastClass: 'circle-check',
      progressBar: progress,
    });
  }

  getEventsService() {
    this.http.getRequest(SettingsProvider.url(`tickets/events`),).subscribe({
      next: (result) => {
        console.log(result, "desde el servicios...");
        if (result['status'] && result['data'] && result['data'].length > 0) {

        } else {
          //this.toast.warning('No se encontraron datos', result['errors'].join(', ') ?? 'No se encontro ningun registro', 4000);
          //this.load.dismiss();
        }
      },
      error: (error) => {
        //console.log(error);
        //this.load.dismiss();
        //this.toast.error('Error de búsqueda', 'Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
      }
    });
  }

  

}