import { ENV } from "../../../../src/app/interfaces/interfaces";

export const environment:ENV = {
    production: false,
    type: 'ready',
    apiUrl: 'https://ready.gateway.test.solunes.com/',
    front_url: 'ctlpmembresias.test.solunes.com/bypass-login',

    title: 'Ready',
    subtitle: 'Admin Membresías',
    description: `"Ready - Admin Membresías" es un sitio en el que puedes administrar el sitio web de Redy Membresías`,
    keywords: 'Ready, Admin Membresías',
    yearProyect: '2025',

    imgLogo: '/assets/img/logo.png',
    imgLogoSVG: '/assets/img/logo.png',
    imgLogoBig: '/assets/img/logo.png',

    header: true,
    sidebar: true,
    footer: true,

    authType: 'auth-2',
    authGoogle: false,
    authFacebook: false,
    authApple: false,
    authGithub: false,

    authRedirect: '/',

    //* Firebase
    firebase: {
        apiKey: "AIzaSyAT21ITZl34FvgPDWMv5fbvKsNKWfmSJPs",
        authDomain: "ready-adee9.firebaseapp.com",
        projectId: "ready-adee9",
        storageBucket: "ready-adee9.firebasestorage.app",
        messagingSenderId: "420822136907",
        appId: "1:420822136907:web:04f1abc8448166a19235ce",
        measurementId: "G-97YD22CVN0"
    },
    firebaseKeyMessage: 'BGKa0yOx7iBM4dofVEOYXWNPsMxFh5QsErQw4U1id93ljlTFELkLKG2wys-CQb35AV_hK_E2Qs6ktgXz8bDuoVo'
};
